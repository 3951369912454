body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}



.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {

  text-align: center;
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

textarea{
  font-family:Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New, monospace;
  font-size: 12px !important;
}
